export default defineNuxtRouteMiddleware(async to => {
  // await until user is resolved
  const { waitUntilResolve } = useUser()
  const { initCodes } = useCodes()
  const { resolveSession } = useAuth()

  await resolveSession(to)

  await Promise.all([
    initCodes(),
    waitUntilResolve(),
  ])
})
