import { defineRule } from 'vee-validate'

export default defineNuxtPlugin(_ => {
  defineRule('required', (value: string) => {
    if (!value || !value.length)
      return 'This field is required'

    return true
  })

  defineRule('isEmail', (value: string) => {
    // Field is empty, should pass
    if (!value || !value.length)
      return true

    // Check if email
    if (!/[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}/.test(value))
      return 'This field must be a valid email'

    return true
  })
})
