export default defineNuxtPlugin({
  name: 'global-use-head',
  async setup () {
    const { platform } = useAppConfig()
    const url = useRequestURL()

    useHead({
      titleTemplate: titleChunk => {
        return titleChunk
          ? `${titleChunk} - ${platform.name}`
          : platform.name
      },
      link: [
        { rel: 'canonical', href: `${platform.domain}${url.pathname}` },
      ],
    })
  },
})
