export default defineAppConfig({
  components: {
    navigation: {
      hidePages: false,
      hideSign: false,
    },
  },
  ui: {
    primary: 'slate',
    gray: 'neutral',
    selectMenu: {
      background: 'bg-neutral-50! ',
      ring: 'ring-0  border-neutral-200 border-1!',
      shadow: 'fubex-shadow',
      height: 'max-h-60',
      input: 'block w-[calc(100%+0.5rem)] fubex-rounded-md rounded-b-0 focus:ring-transparent text-sm px-3 py-1.5 text-neutral-700 dark:text-neutral-200 bg-white dark:bg-neutral-800 border-0 border-b border-neutral-200 dark:border-neutral-700 focus:border-inherit sticky -top-1 -mt-1 mb-1 -mx-1 z-10 placeholder-neutral-400 dark:placeholder-neutral-500 focus:outline-none',
      option: {
        base: 'cursor-pointer',
        active: 'bg-neutral-100 dark:bg-neutral-800',
        hover: 'hover:bg-neutral-100 dark:hover:bg-neutral-900',
      },
    },
    pagination: {
      wrapper: 'flex items-center -space-x-px  ring-0',
      base: 'ring-0 border-neutral-200 border-1',
      default: {
        activeButton: {
          class: 'dark:text-neutral-900',
        },
        inactiveButton: {
          class: 'text-neutral-500 dark:text-neutral-500',
        },
      },
    },
    table: {
      wrapper: 'relative overflow-x-auto',
      base: 'min-w-full table-fixed',
      divide: 'divide-y divide-neutral-300 dark:divide-neutral-200',
      thead: '',
      tbody: 'divide-y divide-neutral-200/50 dark:divide-neutral-700/50',
      tr: {
        base: '',
        active: 'hover:bg-neutral-200/60 dark:hover:bg-neutral-200/10 cursor-pointer',
      },
      default: {
        sortButton: {
          variant: 'ghost',
          class: 'hover:bg-primary-500/20 dark:hover:bg-primary-200/10',
        },

      },
    },
    input: {
      wrapper: 'relative',
      base: 'bg-neutral-50! relative block w-full disabled:cursor-not-allowed disabled:opacity-75 focus:outline-none border-1 border-neutral-200 ring-0',
    },
    skeleton: {
      base: 'animate-pulse',
      background: 'bg-neutral-100 dark:bg-neutral-800',
      rounded: 'rounded-md',
    },
  },
})
