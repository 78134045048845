import validate from "/vercel/path0/node_modules/.pnpm/nuxt@3.12.2_@opentelemetry+api@1.9.0_@parcel+watcher@2.4.1_@types+node@20.14.2_@unocss+reset@_7uok73d2jputk6y3v7wep6ubvi/node_modules/nuxt/dist/pages/runtime/validate.js";
import _01_45setup_45global from "/vercel/path0/layers/base/middleware/01.setup.global.ts";
import manifest_45route_45rule from "/vercel/path0/node_modules/.pnpm/nuxt@3.12.2_@opentelemetry+api@1.9.0_@parcel+watcher@2.4.1_@types+node@20.14.2_@unocss+reset@_7uok73d2jputk6y3v7wep6ubvi/node_modules/nuxt/dist/app/middleware/manifest-route-rule.js";
export const globalMiddleware = [
  validate,
  _01_45setup_45global,
  manifest_45route_45rule
]
export const namedMiddleware = {
  admin: () => import("/vercel/path0/layers/modules/fubex-auth/middleware/admin.ts"),
  auth: () => import("/vercel/path0/layers/modules/fubex-auth/middleware/auth.ts"),
  inspector: () => import("/vercel/path0/layers/modules/fubex-auth/middleware/inspector.ts")
}