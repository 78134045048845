export default defineAppConfig({
  platform: {
    id: 'bitbeli',
    name: 'Bitbeli DEV',
    domain: 'http://localhost:3000',
    defaultCharge: 0.975,
    defaultBitbeliProfitPercent: 1.25,
    makerFee: 0.002, // =0.2%
  },
})

declare module '@nuxt/schema' {
  interface AppConfigInput {
    platform?: {
      id: string
      name: string
      defaultCharge?: number
    }
  }
}
