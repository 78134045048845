import type { User } from '@develit-io/fubex-exchange-prisma'
import type { UserDeep } from '@base/types'

export const useUser = defineStore('user', () => {
  const user = useState<UserDeep | undefined>('user', () => undefined)
  const isFetched = useState('user-isFetched', () => false)

  const { platform } = useAppConfig()

  let stopwatchAuth = () => {}

  const auth = useAuth()
  const { authUser } = storeToRefs(auth)
  const { logout, markUserAsAdmin } = auth

  const getUserData = async (force?: boolean) => {
    if (!authUser.value)
      throw new Error('Invalid Session')

    if (user.value?.userId === authUser.value.id && !force)
      return

    const result = await $fetch('/api/user', { headers: useRequestHeaders(['cookie']) })
    const parsed = superjsonParse<UserDeep>(result)

    isFetched.value = true
    if (!result) {
      // TODO: handle when user is not exist
      return
    }

    if (parsed.role === 'ADMIN')
      markUserAsAdmin()

    user.value = parsed
  }

  const patchUser = async (userDto: User) => {
    if (!authUser.value)
      return createError('Invalid Session')

    const result = await $fetch('/api/user', { method: 'PATCH', body: userDto, headers: useRequestHeaders(['cookie']) })
    await getUserData(true)

    if (!result)
      return createError('User not found')
  }

  stopwatchAuth()
  stopwatchAuth = watch(authUser, async () => {
    if (authUser.value)
      return await getUserData()

    user.value = undefined
  }, {
    immediate: true,
  })

  const waitUntilResolve = async () => {
    if (authUser.value)
      await until(isFetched).toBe(true, { timeout: 3000 })
  }

  const dispose = (stopwatchAuth)

  const isLogged = computed(() => !!user.value)
  const isInspector = computed(() => user.value?.role === 'ADMIN' || (user.value?.role === 'INSPECTOR' && user.value?.platformId === platform.id))
  const isAdmin = computed(() => user.value?.role === 'ADMIN')
  const isExchangeOfficeOwner = computed(() => user.value?.role === 'EXCHANGE_OFFICE_OWNER')

  const verifiedLevelNum = computed(() => user.value?.verifiedLevel?.number ?? 0)
  const identityVerification = computed(() => user.value?.userVerifications?.find(v => v.type === 'IDENTITY'))
  const incomeVerification = computed(() => user.value?.userVerifications?.find(v => v.type === 'INCOME'))
  const limitRemaining = computed(() => (user.value?.verifiedLimit ?? 0) - (user.value?.transactionSum ?? 0))
  const hasLockedName = computed(() => (!user.value?.isCompany && verifiedLevelNum.value >= 1) || verifiedLevelNum.value >= 2)
  const hasLockedCompanyName = computed(() => user.value?.isCompany && verifiedLevelNum.value >= 1)
  const hasLockedCompanyIdNumber = computed(() => user.value?.isCompany && verifiedLevelNum.value >= 1)
  const referralLink = computed(() => `${useRequestURL().origin}/invite/${user.value?.userId}`)

  const rewardPercentExchange = computed(() => user.value?.platform?.referralRewards?.find(r => r.rewardType === 'EXCHANGE')?.rewardPercent ?? 0)
  const rewardEurKyc = computed(() => user.value?.platform?.referralRewards?.find(r => r.rewardType === 'KYC')?.rewardEur ?? 0)
  const rewardEurKyb = computed(() => user.value?.platform?.referralRewards?.find(r => r.rewardType === 'KYB')?.rewardEur ?? 0)

  return {
    user,
    isLogged,
    isInspector,
    isAdmin,
    isExchangeOfficeOwner,
    verifiedLevelNum,
    waitUntilResolve,
    patchUser,
    getUserData,
    isFetched,
    dispose,
    logout,
    limitRemaining,
    identityVerification,
    incomeVerification,
    hasLockedName,
    hasLockedCompanyName,
    hasLockedCompanyIdNumber,
    referralLink,
    rewardPercentExchange,
    rewardEurKyc,
    rewardEurKyb,
  }
})
